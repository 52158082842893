import * as React from "react"
import Layout from "../../components/layout"
import Star from "../../components/star"
import '../../style.css'

const ResourcePage = ({location}) => {
    return (
        <Layout>
            <div>
                {location.state ? <h1>{location.state.title}</h1> : null } 
                <Star />
                {location.state ? 
                    location.state.internalText.map( text => <p>{text}</p>)
                    :
                    null
                }
                
                {
                location.state? 
                    location.state.pdf.accessible ? 
                        <p>Check out the <a href={`/resourceLinks/${location.state.pdf.pdf}`} target="_blank" rel="noreferrer">PDF</a> and <a href={`/resourceLinks/${location.state.pdf.accessible}`} target="_blank" rel="noreferrer">Disability accessible PDF</a></p>
                        :
                        <p>Check out the <a href={`/resourceLinks/${location.state.pdf.pdf}`} target="_blank" rel="noreferrer">PDF</a></p>
                    :
                    null
                }
            </div>
        </Layout>
    )
}

export default ResourcePage
